/*=============================================
=              04. Header Css            =
=============================================*/

.header-sticky{
    &.is-sticky{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
        z-index: 999;
        transition: $transition--default;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
        background: #FFF8F1;
    }
}

.header-right {
    flex-grow: 1;
    align-self: stretch;
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.header__logo {
    flex-basis: 20%;
}
.header__logo {
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
    padding: 14px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.header-area {
   background: #FFF8F1;
}

.header-btn {
    & .btn-default {
        background: $theme-color--default;
        color: $white;
        border-radius: 25px;
        padding: 6px 25px;
        border: 2px solid #ddd;
        font-size: 15px;
        &:hover {
            background-color: #333;
            color: #fff;
            border: 2px solid #333;
        }
    }
}


/*============================== 
    - Navigation Menu Css
===============================*/

.navigation-menu{
    & > ul{
        display: flex;
        & > li{
            margin: 0 24px;
            @media #{$xl-device}{
                margin: 0 22px;
            }
            @media #{$laptop-device}{
                margin: 0 15px;
            }
            @media #{$desktop-device}{
                margin: 0 15px;
            }
            & > a {
                display: block;
                color: $black;
                padding: 25px 2px;
                position: relative;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.18;
            }

            &.has-children{
                & > a{
                    position: relative;
                }

                &--multilevel-submenu{
                    position: relative;
                }

                &:hover{
                    .megamenu{
                        transform: translateY(0);
                        visibility: visible;
                        opacity: 1;
                        &--home-variation{
                            &__item{
                                visibility: visible;
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }

                    }
                    & > .submenu{
                        transform: translateY(0);
                        visibility: visible;
                        opacity: 1;

                    }
                }
                
            }

            &:hover, &.active{
                & > a{
                    &:after{
                        color: $theme-color--default;
                    }
                    & span {
                        color: $theme-color--default;
                    }
                }
            }
        }
    }
    
}



/*===================================
    - Multilevel Submenu Css
====================================*/

.submenu {
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
    border-bottom: 3px solid $theme-color--default;
    background-color: $white;
    transform: translateY(50px);
    transition: $transition--default;
    transition-delay: 0.2s;
    transition-duration: 0.4s;
    visibility: hidden;
    opacity: 0;
    min-width: 240px;
    padding: 20px 0;
    z-index: 9;

    & > li {
        position: relative;
        & > a{
            display: block;
            padding: 5px 30px;
            color: #ababab;
            font-weight: 400;
            transition: 0s;
            & > span{
                position: relative;
                &:after{
                    content: '';
                    width: 0;
                    height: 1px;
                    bottom: 0;
                    position: absolute;
                    left: auto;
                    right: 0;
                    z-index: -1;
                    background-color: $theme-color--default;
                    transition: 0.3s;
                }
            }
            &:hover{
                color: $theme-color--default;
                & > span{
                    &:after{
                        width: 100%;
                        left: 0;
                        right: auto;
                    }
                }
            }
        }
        &:hover {
            & > .submenu{
                transform: translateY(0);
                visibility: visible;
                opacity: 1;
                z-index: 9;

            }
        }
        
        &.active {
            & > a{
                color: $theme-color--default;
            }
        }
        
        &.has-children{
            & > a{
                position: relative;
                transition: $transition--default; 
                &:after{
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    content: '\f105';
                    font-size: 14px;
                    vertical-align: middle;
                    color: #ababab;
                }

                

                &:hover{
                    &:after{
                        color: $theme-color--default;
                    }
                }
            }
            &.active {
                & > a{
                    color: $theme-color--default;
                }
            }
        }
    }

    & .submenu{
        top: 0px;
        left: 100%;
        right: auto;

        &.left{
            right: 100%;
            left: auto;
        }
       
        & .submenu{
            top: 0;
            left: 100%;
            right: auto;
            &.left{
                right: 100%;
                left: auto;
            }
            & .submenu{
                top: 0;
                left: 100%;
                right: auto;
                &.left{
                    right: 100%;
                    left: auto;
                }
                & .submenu{
                    top: 0;
                    left: 100%;
                    right: auto;
                    &.left{
                        right: 100%;
                        left: auto;
                    }
                }
            }
        }
    }  
}

/*=========================================
    - Mega Menu Css
===========================================*/

.megamenu{
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
    border-bottom: 3px solid $theme-color--default;
    background-color: $white;
    transform: translateY(50px);
    transition: $transition--default;
    transition-delay: 0.2s;
    transition-duration: 0.4s;
    visibility: hidden;
    opacity: 0;
    z-index: 9;


    &--mega{
        min-width: 980px;
        width: 100%;
        padding: 35px 20px 30px;
        display: flex;
        justify-content: space-around;

        @media #{$laptop-device}{
            padding-left: 50px;
            padding-right: 50px;
        }

        @media #{$laptop-device}{
            min-width: 700px;
        }
        & > li{
            flex-basis: 22%;
            padding-left: 15px;
            padding-right: 15px;
            .page-list-title{
                font-size: 14px;
                margin-bottom: 20px;
                color: $theme-color--black;

            }

            & > ul{
                & > li{
                    & > a{
                        padding: 10px 0;
                        color: #ababab;
                        line-height: 1.2;
                        transition: 0.1s; 
                        &:hover{
                            color: $theme-color--default;
                            span{
                                &:after{
                                    width: 100%;
                                    left: 0;
                                    right: auto;
                                }
                            }
                        }
                        & > span{
                            position: relative;
                            &:after{
                                content: '';
                                width: 0;
                                height: 1px;
                                bottom: 0;
                                position: absolute;
                                left: auto;
                                right: 0;
                                z-index: -1;
                                background-color: $theme-color--default;
                                transition: 0.3s;
                            }
                        }
                    }
                    &.active {
                        & > a{
                            color: $theme-color--default;
                        }
                    }
                }
            }
        }
    }
}

.container-fluid {
    & .megamenu{
        &--mega{
            padding: 35px 220px 30px;

            @media #{$laptop-device}{
                padding-left: 50px;
                padding-right: 50px;
            }
            @media #{$laptop-device}{
                padding-left: 50px;
                padding-right: 50px;
            }

            @media #{$laptop-device}{
                min-width: 700px;
            }
        }
    }
}

/* Mobile Mavigation icon */

.mobile-navigation-icon{
    width: 24px;
    height: 25px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    margin-left: 30px;

    &:hover{
        i{
            background-color: $theme-color--default;
            &:before{
                width: 80%;
                background-color: $theme-color--default;
            }
            &:after{
                background-color: $theme-color--default;
                width: 60%;
            }
        }
    }

    i{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 2px;
        background-color: #333;
        transition: $transition--default;

        &:before{
            position: absolute;
            bottom: 8px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #333;
            content: "";
            transition: $transition--default;
        }

        &:after{
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #333;
            content: "";
            transition: $transition--default;
        }
    }
    &.white-md-icon {
        @media #{$desktop-device}{
            i{
                background-color: #fff;
                &:before{
                    background-color: #fff;
                }
                &:after{
                    background-color: #fff;
                }
            }
            &:hover{
                i{
                    background-color: $theme-color--default;
                    &:before{
                        width: 80%;
                        background-color: $theme-color--default;
                    }
                    &:after{
                        background-color: $theme-color--default;
                        width: 60%;
                    }
                }
            }
        }
    }
}
.is-sticky {
    .white-md-icon {
        @media #{$desktop-device}{
            i{
                background-color: #333;
                &:before{
                    background-color: #333;
                }
                &:after{
                    background-color: #333;
                }
            }
        }
    }
}


/*================================ 
    - Mobile Menu Overlay Css
==================================*/

.mobile-menu-overlay{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $theme-color--black;
    overflow: auto;
    transition: $transition--default;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
    //cursor: url(../images/icons/light-close.png) 16 16, pointer;
    visibility: hidden;
    opacity: 0;
    
    &__inner{
        transform: translateX(120%);
        width: 400px;
        height: 100%;
        float: right;
        cursor: default;
        background: $theme-color--default;
        transition: $transition--default;
        overflow-y: auto;

        @media #{$extra-small-mobile}{
            width: 300px;
        }
    }

    &.active{
        .mobile-menu-overlay__inner{
            transform: translateX(00%);
        }
        visibility: visible;
        opacity: 1;
    }

    &__header{
        background-color: $white;
        padding: 15px 0;
        .mobile-navigation-close-icon{
            position: relative;
            cursor: pointer;
            height: 40px;
            width: 40px;
            line-height: 40px;
            display: inline-block;
            &:before{
                position: absolute;
                top: 23px;
                left: 8px;
                content: '';
                width: 24px;
                height: 3px;
                background: $theme-color--black;
                transform-origin: 50% 50%;
                transform: rotate(45deg);
                transition: $transition--default;
            }
            &:after{
                position: absolute;
                top: 23px;
                left: 8px;
                content: '';
                width: 24px;
                height: 3px;
                background: $theme-color--black;
                transform-origin: 50% 50%;
                transform: rotate(-45deg);
                transition: $transition--default;
            }
            &:hover{
                color: $theme-color--default;
                &:before, &:after{
                    transform: none;
                }
            }
        }
    }
    &__body{
        
        padding: 20px 40px 100px;
        .offcanvas-navigation{
            & > ul{
                & > li{
                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

                    & > a{
                        display: block;
                        color: $white;
                        padding-top: 18px;
                        padding-bottom: 18px;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.5;
                        transition: $transition--default;

                        &:hover{
                            color: $white;
                        }
                    }

                    &:last-child{
                        border-bottom: 0;
                    }

                    &.has-children{
                        position: relative;

                        &.active{
                            .menu-expand{
                                &:before{
                                    content: '\f11b';
                                }
                            }
                        }

                       .menu-expand{
                            position: absolute;
                            right: 0;
                            top: 12px;
                            width: 40px;
                            height: 40px;
                            background: rgba(255,255,255,.1);
                            color: #fff;
                            text-align: center;
                            line-height: 40px;
                            cursor: pointer;
                            transition: $transition--default;

                            &:hover{
                                background: rgba(255,255,255,.2);
                            }

                            &:before{
                                content: "\f109";
                                font-family: "Flaticon";

                            }
                       }

                       .sub-menu{
                        padding: 12px 0 14px 10px;
                        border-top: 1px solid rgba(255, 255, 255, 0.15);
                           li{
                            border-bottom: 1px solid rgba(255, 255, 255, 0.15);

                            a{
                                display: block;
                                font-size: 15px;
                                color: rgba(255, 255, 255, 0.7);
                                font-weight: 500;
                                line-height: 1.5;
                                padding: 10px 0;

                                &:hover{
                                    color: $white;
                                }
                            }

                            &:last-child{
                                border-bottom: 0;
                            }
                               &.has-children{
                                   position: relative;

                                   &.active{
                                    .menu-expand{
                                        &:before{
                                            content: "\f109";
                                            font-family: "Flaticon";
                                        }
                                    }
                                }
        
                               .menu-expand{
                                    position: absolute;
                                    right: 0;
                                    top: 6px;
                                    width: 30px;
                                    height: 30px;
                                    background: rgba(255,255,255,.1);
                                    color: #fff;
                                    text-align: center;
                                    line-height: 30px;
                                    cursor: pointer;
                                    transition: $transition--default;
        
                                    &:hover{
                                        background: rgba(255,255,255,.2);
                                    }
        
                                    &:before{
                                        content: "\f109";
                                        font-size: 16px;
        
                                    }
                               }
                            }
                               
                        }

                           .sub-menu{
                                li{
                                    &.has-children{
                                        position: relative;

                                    &.active{
                                        .menu-expand{
                                            &:before{
                                                content: "\f119";
                                            }
                                        }
                                    }
                
                                    .menu-expand{
                                            &:before{
                                                content: '\f119';
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                           }
                        }
                    }
                }
            }
        }
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}

@-webkit-keyframes fadeInDown {
    0% {
        -webkit-transform: translate3d(0, -100%, 0);
        opacity: 0;
        transform: translate3d(0, -100%, 0)
    }

    to {
        -webkit-transform: translateZ(0);
        opacity: 1;
        transform: translateZ(0)
    }
}

@keyframes fadeInDown {
    0% {
        -webkit-transform: translate3d(0, -100%, 0);
        opacity: 0;
        transform: translate3d(0, -100%, 0)
    }

    to {
        -webkit-transform: translateZ(0);
        opacity: 1;
        transform: translateZ(0)
    }
}


/*=====  End of Header  ======*/